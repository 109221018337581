@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.title, .home__title, .artists__title, .artist__artist-name {
  font-family: "Cormorant Garamond";
  -webkit-text-stroke: 1px black;
}

:root {
  --fs-50: 0.6rem;
  --fs-80: 0.7rem;
  --fs-100: 0.8rem;
  --fs-200: 0.9rem;
  --fs-300: 1.2rem;
  --fs-400: 1.6rem;
  --fs-500: 2.5rem;
  --fs-600: 3.5rem;
  --fs-650: 4.5rem;
  --fs-700: 5rem;
  --fs-800: 8rem;
  --fs-1000: 12rem;
}
@media only screen and (min-width: 1024px) {
  :root {
    --fs-80: 0.6rem;
    --fs-100: 1rem;
    --fs-200: 1.5rem;
    --fs-300: 2rem;
    --fs-400: 3rem;
    --fs-500: 6rem;
    --fs-600: 8rem;
    --fs-650: 10rem;
    --fs-700: 12rem;
    --fs-800: 16rem;
    --fs-1000: 15rem;
  }
}
@media only screen and (min-width: 1181px) {
  :root {
    --fs-50: 0.5rem;
    --fs-80: 0.8rem;
    --fs-100: 1rem;
    --fs-200: 1.2rem;
    --fs-300: 1.3rem;
    --fs-400: 1.4rem;
    --fs-500: 1.5rem;
    --fs-600: 3rem;
    --fs-650: 5rem;
    --fs-700: 7rem;
    --fs-800: 12rem;
    --fs-1000: 23rem;
  }
}
@media only screen and (min-width: 1680px) {
  :root {
    --fs-50: 0.5rem;
    --fs-80: 0.8rem;
    --fs-100: 1rem;
    --fs-200: 1.2rem;
    --fs-300: 1.3rem;
    --fs-400: 1.4rem;
    --fs-500: 1.5rem;
    --fs-600: 2.4rem;
    --fs-650: 5rem;
    --fs-700: 7rem;
    --fs-800: 16rem;
    --fs-1000: 34rem;
  }
}
@media only screen and (min-width: 2560px) {
  :root {
    --fs-80: 1.6rem;
    --fs-100: 2rem;
    --fs-200: 2.2rem;
    --fs-300: 2.4rem;
    --fs-400: 2.4rem;
    --fs-500: 3.5rem;
    --fs-600: 5rem;
    --fs-650: 8rem;
    --fs-700: 14rem;
    --fs-800: 24rem;
    --fs-1000: 36rem;
  }
}
@media only screen and (min-width: 3840px) {
  :root {
    --fs-80: 1.6rem;
    --fs-100: 2rem;
    --fs-200: 2.2rem;
    --fs-300: 2.4rem;
    --fs-400: 2.8rem;
    --fs-500: 4rem;
    --fs-600: 6rem;
    --fs-650: 10rem;
    --fs-700: 14rem;
    --fs-800: 32rem;
    --fs-1000: 62rem;
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.artists__title {
  color: #6dc0d5;
  width: 100%;
  text-align: center;
  font-size: var(--fs-650);
  font-style: italic;
  margin-bottom: 3rem;
}
@media (max-width: 600px) {
  .artists__title {
    font-size: var(--fs-500);
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .artists__title {
    font-size: var(--fs-500);
  }
}

.artists__container {
  padding: 0 3rem;
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-auto-rows: 50px;
  grid-auto-flow: dense;
}
@media (max-width: 600px) {
  .artists__container {
    grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
    grid-auto-rows: 30px;
    padding: 0;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .artists__container {
    grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .artists__container {
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .artists__container {
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
  }
}

.artist__card {
  height: 42vh;
  width: 15vw;
  display: inline-block;
  position: relative;
}
.artist__card .defaultImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left top;
  transition: opacity 300ms ease-in;
}
.artist__card:hover {
  cursor: pointer;
}
.artist__card:hover img:nth-of-type(2) {
  opacity: 0;
}
.artist__card:hover .artist__artist-name {
  color: #00d130;
}

.artist__card-landscape-0 {
  height: 100%;
  width: 100%;
  grid-column: span 8;
  grid-row: span 6;
}
@media (min-width: 3840px) {
  .artist__card-landscape-0 {
    grid-column: span 12;
    grid-row: span 9;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .artist__card-landscape-0 {
    grid-column: span 7;
    grid-row: span 3;
  }
}

.artist__card-landscape-1 {
  height: 100%;
  width: 100%;
  grid-column: span 6;
  grid-row: span 4;
}
@media (min-width: 3840px) {
  .artist__card-landscape-1 {
    grid-column: span 10;
    grid-row: span 7;
  }
}

.artist__card-portrait-0 {
  height: 100%;
  width: 100%;
  grid-row: span 8;
  grid-column: span 6;
}
@media (max-width: 600px) {
  .artist__card-portrait-0 {
    grid-row: span 12;
    grid-column: span 8;
  }
}
@media (min-width: 3840px) {
  .artist__card-portrait-0 {
    grid-row: span 12;
    grid-column: span 9;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .artist__card-portrait-0 {
    grid-row: span 6;
    grid-column: span 7;
  }
}

.artist__card-portrait-1 {
  height: 100%;
  width: 100%;
  grid-row: span 6;
  grid-column: span 4;
}
@media (min-width: 3840px) {
  .artist__card-portrait-1 {
    grid-row: span 10;
    grid-column: span 7;
  }
}

.artist__card-square-0 {
  height: 100%;
  width: 100%;
  grid-row: span 8;
  grid-column: span 8;
}
@media (min-width: 3840px) {
  .artist__card-square-0 {
    grid-row: span 12;
    grid-column: span 12;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .artist__card-square-0 {
    grid-row: span 4;
    grid-column: span 7;
  }
}

.artist__card-square-1 {
  height: 100%;
  width: 100%;
  grid-row: span 6;
  grid-column: span 6;
}
@media (min-width: 3840px) {
  .artist__card-square-1 {
    grid-row: span 9;
    grid-column: span 9;
  }
}

.artist__artist-name {
  color: rgba(0, 209, 48, 0.5);
  font-size: var(--fs-600);
  font-weight: bold;
  line-height: 5rem;
  position: absolute;
  font-family: "DM Sans";
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  padding: 1rem 1.5rem;
  box-sizing: border-box;
}
.artist__artist-name:hover {
  color: #00d130;
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .artist__artist-name {
    line-height: 3rem;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .artist__artist-name {
    font-size: var(--fs-400);
    line-height: 3rem;
  }
}

.artist__modal-container {
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: opacity 1.3s ease;
  height: 0;
  width: 0;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
}

.artist__modal-container-open {
  opacity: 1;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.artist__modal-open {
  position: fixed;
}

.artist__modal {
  background: rgba(0, 0, 0, 0.95);
  height: 90%;
  width: 95%;
  top: 5%;
  border-radius: 1rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  overflow: hidden;
  z-index: 10;
  position: absolute;
}

.artist__gallery {
  position: relative;
  width: 100%;
  height: 32vw;
  margin: 100px 0 100px 0%;
  font-family: "Helvetica Neue", sans-serif;
  perspective: 750px;
  transform-style: preserve-3d;
  pointer-events: none;
}
@media (max-width: 600px) {
  .artist__gallery {
    margin: 0;
    height: 50vh;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .artist__gallery {
    margin: 5vh 0 0 0%;
    perspective: 350px;
    height: 65vh;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .artist__gallery {
    height: 45vw;
  }
}
.artist__gallery label,
.artist__gallery label .displayImage {
  transform-style: inherit;
  position: relative;
  height: 100%;
  max-height: 45vh;
  max-width: 30vw;
  object-fit: contain;
  color: white;
  font-size: 70px;
  font-weight: bold;
  border-radius: 3px;
  cursor: crosshair;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 400ms ease;
  pointer-events: auto;
}
@media (max-width: 600px) {
  .artist__gallery label,
.artist__gallery label .displayImage {
    max-height: 33vh;
    max-width: 50vw;
    transform: translateY(0rem) translateX(0rem);
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .artist__gallery label,
.artist__gallery label .displayImage {
    max-height: 60vh;
    max-width: 40vw;
    transform: translateY(-5rem) translateX(5rem);
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .artist__gallery label,
.artist__gallery label .displayImage {
    transform: none;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .artist__gallery label,
.artist__gallery label .displayImage {
    max-height: 45vh;
  }
}
.artist__gallery label {
  top: 20%;
  width: auto;
  position: absolute;
  max-height: 45vh;
  background-color: transparent;
  display: inline-block;
  cursor: all-scroll;
}
@media (max-width: 600px) {
  .artist__gallery label {
    top: 1%;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .artist__gallery label {
    max-height: 45vh;
  }
}
.artist__gallery label .image-name {
  position: absolute;
  top: 105%;
  color: white;
  z-index: 10;
  font-size: var(--fs-100);
  font-weight: 200;
  font-family: "Cormorant Garamond";
  transition: opacity 0.3s ease;
  left: 40%;
}
@media (max-width: 600px) {
  .artist__gallery label .image-name {
    top: 100%;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .artist__gallery label .image-name {
    top: 75%;
    left: 70%;
    width: 100%;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .artist__gallery label .image-name {
    top: 95%;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .artist__gallery label .image-name {
    top: 100%;
    left: 40%;
  }
}
.artist__gallery .button-group {
  position: relative;
  top: 110%;
  display: flex;
}
@media (max-width: 600px) {
  .artist__gallery .button-group {
    top: 72%;
    left: 0;
    margin: 0 auto;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .artist__gallery .button-group {
    top: 130%;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .artist__gallery .button-group {
    top: 100%;
    left: 0;
  }
}
.artist__gallery input[type=radio] {
  position: relative;
  appearance: none;
  width: 2rem;
  height: 2rem;
  border: 0.2rem solid red;
  border-radius: 50%;
  color: red;
  top: 108%;
  left: 50%;
  margin: 0 15px 0 0;
  opacity: 0.4;
  transform: translateX(-83px);
  display: grid;
  place-content: center;
  cursor: pointer;
  pointer-events: auto;
}
@media (max-width: 600px) {
  .artist__gallery input[type=radio] {
    border: 0.12rem solid red;
    height: 1.2rem;
    width: 1.2rem;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .artist__gallery input[type=radio] {
    border: 0.12rem solid red;
    height: 1.2rem;
    width: 1.2rem;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .artist__gallery input[type=radio] {
    border: 0.12rem solid red;
    height: 1.2rem;
    width: 1.2rem;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .artist__gallery input[type=radio] {
    border: 0.12rem solid red;
    height: 1.2rem;
    width: 1.2rem;
  }
}
.artist__gallery input input[type=radio]:nth-child(5) {
  margin-right: 0px;
}
.artist__gallery input[type=radio]::before {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  transform: scale(0);
  background: red;
  transition: 120ms transform ease-in-out;
}
@media (max-width: 600px) {
  .artist__gallery input[type=radio]::before {
    height: 0.7rem;
    width: 0.7rem;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .artist__gallery input[type=radio]::before {
    height: 0.7rem;
    width: 0.7rem;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .artist__gallery input[type=radio]::before {
    height: 0.7rem;
    width: 0.7rem;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .artist__gallery input[type=radio]::before {
    height: 0.7rem;
    width: 0.7rem;
  }
}
.artist__gallery input[type=radio]:checked {
  opacity: 1;
}
.artist__gallery input[type=radio]:checked::before {
  transform: scale(1);
}

.modal-show {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 200;
  outline: none;
}

.modal-hide {
  display: none;
}

.modal-container {
  width: 90vw;
  height: 90vh;
  display: grid;
  position: relative;
  grid-template-columns: 2fr 3fr;
  border-radius: 1em;
  background: rgba(0, 0, 0, 0.9);
  animation: fade-out-modal 500ms forwards;
}
@media (max-width: 600px) {
  .modal-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 2-rem;
    height: 80vh;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .modal-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .modal-container {
    display: grid;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .modal-container {
    left: -0.8rem;
    top: -0.4rem;
    height: 80vh;
  }
}
.modal-container .modal-close-button {
  position: absolute;
  right: 0;
  color: white;
  top: 0;
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  font-size: var(--fs-500);
  padding: 0.3rem 0.8rem;
}
@media (max-width: 600px) {
  .modal-container .modal-close-button {
    font-size: var(--fs-400);
    padding: 0.4rem;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .modal-container .modal-close-button {
    font-size: var(--fs-300);
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .modal-container .modal-close-button {
    padding: 0.3rem 0.4rem;
  }
}
.modal-container .artist__info {
  padding: 0.5rem 0rem 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 600px) {
  .modal-container .artist__info {
    height: 35vh;
  }
}
.modal-container .artist__name {
  color: #6dc0d5;
  font-size: var(--fs-500);
  margin-bottom: 2rem;
}
@media (max-width: 600px) {
  .modal-container .artist__name {
    font-size: var(--fs-400);
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .modal-container .artist__name {
    margin-bottom: 1rem;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .modal-container .artist__name {
    margin-bottom: 1rem;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .modal-container .artist__name {
    font-size: var(--fs-300);
  }
}
.modal-container .artist__bio {
  color: white;
  font-size: var(--fs-300);
  line-height: 3rem;
}
@media (max-width: 600px) {
  .modal-container .artist__bio {
    line-height: 1rem;
    font-size: var(--fs-80);
    max-height: 30vh;
    overflow-y: scroll;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .modal-container .artist__bio {
    line-height: 1rem;
    font-size: var(--fs-80);
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .modal-container .artist__bio {
    font-size: var(--fs-200);
  }
}
@media (min-width: 2560px) and (max-width: 3839px) {
  .modal-container .artist__bio {
    font-size: var(--fs-200);
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .modal-container .artist__bio {
    max-height: 55vh;
    overflow-y: scroll;
  }
}
.modal-container .artist__bio a {
  text-decoration: none;
  color: white;
}
.modal-container .artist__bio p {
  margin-bottom: 1rem;
  text-indent: 3rem;
  line-height: 3rem;
}
@media (max-width: 600px) {
  .modal-container .artist__bio p {
    line-height: 1rem;
    font-size: var(--fs-80);
    text-indent: 0.5rem;
    margin-bottom: 0;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .modal-container .artist__bio p {
    line-height: 1rem;
    font-size: var(--fs-80);
    text-indent: 0.5rem;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .modal-container .artist__bio p {
    font-size: var(--fs-80);
    line-height: 1.5rem;
    text-indent: 1rem;
    margin-bottom: unset;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .modal-container .artist__bio p {
    font-size: var(--fs-100);
    margin-bottom: 0.5rem;
    line-height: 1.7rem;
    text-indent: 2rem;
  }
}
@media (min-width: 2560px) and (max-width: 3839px) {
  .modal-container .artist__bio p {
    font-size: var(--fs-80);
    margin-bottom: 0.5rem;
    line-height: 2rem;
    text-indent: 2rem;
  }
}
.modal-container .artist__bio li {
  text-indent: 3rem;
}
@media (max-width: 600px) {
  .modal-container .artist__bio li {
    text-indent: 0.5rem;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .modal-container .artist__bio li {
    text-indent: 0.5rem;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .modal-container .artist__bio li {
    text-indent: 1rem;
    line-height: 1.7rem;
    font-size: var(--fs-80);
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .modal-container .artist__bio li {
    line-height: 1.7rem;
    font-size: var(--fs-100);
  }
}
@media (min-width: 2560px) and (max-width: 3839px) {
  .modal-container .artist__bio li {
    line-height: 1.7rem;
    font-size: var(--fs-80);
    text-indent: 0;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .modal-container .artist__bio {
    font-size: var(--fs-100);
  }
}
.modal-container .modal-image-viewpane {
  overflow: hidden;
}

.modal-container[open] {
  animation: fade-in-modal 500ms forwards;
  outline: none;
}

.modal-container[open] .modal-show {
  animation: fade-in-modal 500ms forwards;
  outline: none;
}

.modal-container::backdrop {
  background: black;
}

@keyframes fade-in-modal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out-modal {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.text-wrapper {
  position: absolute;
  top: 10vh;
  left: 0%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .text-wrapper {
    top: 0vh;
  }
}

.home__title {
  width: 8ch;
  color: #6dc0d5;
  margin-left: 77rem;
  margin-top: 8rem;
  z-index: 0;
  font-size: var(--fs-700);
}
@media (max-width: 600px) {
  .home__title {
    margin-left: 11rem;
    margin-top: -2rem;
    font-size: var(--fs-500);
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .home__title {
    font-size: var(--fs-700);
    margin-top: 0;
    margin-left: 20rem;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .home__title {
    margin-left: 30rem;
    margin-top: -5rem;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .home__title {
    margin-left: 40rem;
    margin-top: 0rem;
  }
}
@media (min-width: 2560px) and (max-width: 3839px) {
  .home__title {
    margin-left: 50rem;
    margin-top: 3rem;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .home__title {
    margin-top: 6rem;
  }
}
.home__title span {
  display: block;
  line-height: 20rem;
}
@media (max-width: 600px) {
  .home__title span {
    line-height: 3rem;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .home__title span {
    line-height: 10rem;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .home__title span {
    line-height: 10rem;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .home__title span {
    line-height: 10rem;
  }
}
@media (min-width: 2560px) and (max-width: 3839px) {
  .home__title span {
    line-height: 16rem;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .home__title span {
    line-height: 4rem;
  }
}

.title-large {
  font-size: var(--fs-800);
}
@media (max-width: 600px) {
  .title-large {
    font-size: var(--fs-600);
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .title-large {
    font-size: var(--fs-800);
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .title-large {
    font-size: var(--fs-700);
  }
}

@media (min-width: 601px) and (max-width: 1180px) {
  .title-small {
    font-size: var(--fs-700);
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .title-small {
    font-size: var(--fs-600);
  }
}

.year {
  position: absolute;
  font-family: "Cormorant Garamond";
  font-size: var(--fs-1000);
  top: 30rem;
  left: 30rem;
  color: rgba(0, 209, 48, 0.23);
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.3);
  width: 100%;
}
@media (max-width: 600px) {
  .year {
    left: 0;
    top: 8rem;
    z-index: 10;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .year {
    top: 30rem;
    left: 0;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .year {
    top: 10rem;
    left: 15rem;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .year {
    top: 10rem;
    left: 22rem;
  }
}
@media (min-width: 2560px) and (max-width: 3839px) {
  .year {
    top: 26rem;
    left: 22rem;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .year {
    top: 20rem;
    left: 12rem;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .year {
    top: 11rem;
  }
}

.about-text {
  position: absolute;
  top: 120rem;
  left: 15rem;
  width: 60ch;
  font-family: "DM Sans";
  font-size: var(--fs-400);
  padding: 0 6rem;
}
@media (max-width: 600px) {
  .about-text {
    top: 28rem;
    left: 1rem;
    padding: 0;
  }
  .about-text p {
    font-size: var(--fs-200);
    line-height: 1rem;
    width: 20ch;
    opacity: 1;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .about-text {
    top: 57rem;
    left: 0;
    padding: 0;
    width: 26ch;
  }
  .about-text p {
    line-height: 1rem;
    opacity: 1;
    margin-left: 5%;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .about-text {
    top: 45rem;
    left: 6rem;
  }
  .about-text p {
    line-height: 2rem;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .about-text {
    top: 50rem;
  }
  .about-text p {
    line-height: 2rem;
  }
}
@media (min-width: 2560px) and (max-width: 3839px) {
  .about-text {
    top: 77rem;
    left: 0rem;
    width: 45ch;
  }
}
.about-text p {
  margin-bottom: 1rem;
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .about-text {
    top: 40rem;
    left: 0;
    width: 20ch;
  }
  .about-text p {
    font-size: var(--fs-200);
    line-height: 1.5rem;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .about-text {
    top: 27rem;
    width: 21ch;
  }
}

.location-text {
  position: absolute;
  top: 184rem;
  right: 17rem;
  max-width: 100ch;
}
@media (max-width: 600px) {
  .location-text {
    top: 67rem;
    max-width: 17ch;
    right: 0;
  }
  .location-text p {
    font-size: var(--fs-200);
    line-height: 1rem;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .location-text {
    top: 100rem;
    max-width: 30ch;
    right: 2rem;
  }
  .location-text p {
    line-height: 1rem;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .location-text {
    top: 98rem;
    right: 10vw;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .location-text {
    top: 112rem;
    right: 30rem;
  }
}
@media (min-width: 3840px) {
  .location-text {
    top: 216vh;
    right: 11vw;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .location-text {
    top: 90rem;
    right: 10vw;
    max-width: 40ch;
  }
  .location-text p {
    line-height: 1.5rem;
    font-size: var(--fs-200);
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .location-text {
    top: 65rem;
    right: 1vw;
    max-width: 27ch;
  }
}

.home-images {
  display: flex;
  position: relative;
  width: 100%;
}
.home-images ul {
  position: absolute;
  width: 100%;
  height: auto;
}
.home-images ul span {
  z-index: 1;
  position: absolute;
  font-family: "Cormorant Garamond";
  font-size: var(--fs-700);
  right: 5rem;
  font-weight: 700;
  color: #ffffff;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.8);
}
.home-images ul span a {
  text-decoration: none;
  color: inherit;
}
@media (max-width: 600px) {
  .home-images ul span {
    right: 0.5rem;
    color: #6dc0d5;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.3);
    font-size: var(--fs-500);
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .home-images ul span {
    font-size: var(--fs-500);
  }
}
.home-images ul img {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  opacity: 1;
  object-fit: contain;
  height: 100%;
  transition: all 0.5s ease-in-out;
  z-index: 0;
}
.home-images ul .default-image:hover {
  opacity: 0;
}
.home-images ul li {
  position: absolute;
  overflow: hidden;
  transition: all 0.8s ease-in-out;
}
.home-images ul li:nth-of-type(1) {
  top: 12rem;
  height: 80rem;
  width: 53rem;
  left: 0;
}
@media (max-width: 600px) {
  .home-images ul li:nth-of-type(1) {
    top: 0;
    width: 100%;
    height: 15rem;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .home-images ul li:nth-of-type(1) {
    top: 6rem;
    height: 30rem;
    width: 20rem;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .home-images ul li:nth-of-type(1) {
    height: 33rem;
    top: 0;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .home-images ul li:nth-of-type(1) {
    top: 0;
    height: 43rem;
    width: 29rem;
  }
}
@media (min-width: 2560px) and (max-width: 3839px) {
  .home-images ul li:nth-of-type(1) {
    top: 0;
    height: 65rem;
    width: 43rem;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .home-images ul li:nth-of-type(1) {
    top: 0rem;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .home-images ul li:nth-of-type(1) {
    width: 100%;
    height: 22rem;
    top: 2rem;
  }
}
.home-images ul li:nth-of-type(2) {
  top: 100rem;
  height: 100rem;
  width: 75rem;
  right: 0;
}
@media (max-width: 600px) {
  .home-images ul li:nth-of-type(2) {
    top: 28rem;
    width: 8rem;
    height: 12rem;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .home-images ul li:nth-of-type(2) {
    top: 55rem;
    width: 20rem;
    height: 28rem;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .home-images ul li:nth-of-type(2) {
    top: 40rem;
    width: 26rem;
    height: 35rem;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .home-images ul li:nth-of-type(2) {
    top: 45rem;
    height: 43rem;
    width: 33rem;
  }
}
@media (min-width: 2560px) and (max-width: 3839px) {
  .home-images ul li:nth-of-type(2) {
    top: 68rem;
    height: 75rem;
    width: 57rem;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .home-images ul li:nth-of-type(2) {
    top: 38rem;
    width: 25rem;
    height: 35rem;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .home-images ul li:nth-of-type(2) {
    top: 26rem;
    width: 17rem;
    height: 25rem;
  }
}
.home-images ul li:nth-of-type(3) {
  top: 175vh;
  height: 100rem;
  width: 115rem;
  left: 0;
}
@media (max-width: 600px) {
  .home-images ul li:nth-of-type(3) {
    top: 65rem;
    width: 10rem;
    height: 10rem;
  }
  .home-images ul li:nth-of-type(3) img {
    margin-bottom: 15rem;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .home-images ul li:nth-of-type(3) {
    top: 90rem;
    width: 25rem;
    height: 25rem;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .home-images ul li:nth-of-type(3) {
    top: 85rem;
    height: 34rem;
    width: 40rem;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .home-images ul li:nth-of-type(3) {
    top: 95rem;
    height: 44rem;
    width: 50rem;
  }
}
@media (min-width: 2560px) and (max-width: 3839px) {
  .home-images ul li:nth-of-type(3) {
    top: 160rem;
    height: 55rem;
    width: 62rem;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .home-images ul li:nth-of-type(3) {
    top: 80rem;
    width: 30rem;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .home-images ul li:nth-of-type(3) {
    top: 60rem;
    width: 18rem;
    height: 16rem;
  }
}

.contact__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.contact__heading {
  font-size: var(--fs-600);
  text-align: center;
}
@media (max-width: 600px) {
  .contact__heading {
    font-size: var(--fs-500);
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .contact__heading {
    font-size: var(--fs-400);
  }
}

.contact__instructions {
  font-size: var(--fs-300);
  margin-top: 5rem;
  width: 60ch;
}
@media (max-width: 600px) {
  .contact__instructions {
    width: 25ch;
    margin: 2rem;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .contact__instructions {
    font-size: var(--fs-200);
    width: 50ch;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .contact__instructions {
    margin-top: 2rem;
  }
}

.contact__cards {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-top: 5rem;
}
@media (max-width: 600px) {
  .contact__cards {
    width: 95%;
    margin-top: 1rem;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .contact__cards {
    width: 80%;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .contact__cards {
    width: 60%;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .contact__cards {
    width: 60%;
  }
}
@media (min-width: 2560px) and (max-width: 3839px) {
  .contact__cards {
    width: 60%;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .contact__cards {
    width: 80%;
    margin-top: 2rem;
  }
}

.contact__card {
  display: grid;
  grid-template-columns: 1fr 5fr;
  border: 1px solid black;
  border-radius: 1rem;
  height: auto;
  padding: 2rem;
  margin-bottom: 2rem;
  align-items: center;
}
.contact__card:hover {
  background-color: rgba(0, 209, 48, 0.2);
}
@media (max-width: 600px) {
  .contact__card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
  .contact__card:last-child {
    margin-bottom: 15rem;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .contact__card:nth-of-type(3) {
    margin-bottom: 5rem;
  }
}

.contact__icon img {
  width: 8rem;
  margin-right: 2rem;
}
@media (max-width: 600px) {
  .contact__icon img {
    width: 3rem;
    margin-right: 0;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .contact__icon img {
    width: 4rem;
  }
}

.contact__info a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.contact__info p {
  line-height: 2rem;
}
@media (max-width: 600px) {
  .contact__info p {
    line-height: 1.5rem;
  }
}
@media (min-width: 3840px) {
  .contact__info p {
    line-height: 3rem;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .contact__info p {
    font-size: var(--fs-200);
  }
}

.contact__information {
  display: grid;
  grid-template-columns: 7fr 3fr;
}
.contact__information a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.contact__information .info__label {
  margin-right: 3rem;
}
.contact__information p {
  display: inline-block;
  line-height: 2rem;
  max-width: 30ch;
}
@media (min-width: 3840px) {
  .contact__information p {
    line-height: 3rem;
  }
}

.Entertainment__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.Entertainment__text {
  width: 50%;
  margin-top: 5rem;
}
@media (max-width: 600px) {
  .Entertainment__text {
    margin-top: 2rem;
    width: 90%;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .Entertainment__text {
    margin-top: 5rem;
    width: 80%;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .Entertainment__text {
    width: 70%;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .Entertainment__text {
    margin-top: 3rem;
    width: 90%;
  }
}

.Entertainment__heading {
  text-align: center;
  font-size: var(--fs-500);
  width: 100%;
}
@media (max-width: 600px) {
  .Entertainment__heading {
    font-size: var(--fs-400);
    line-height: 2rem;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .Entertainment__heading {
    width: 100%;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .Entertainment__heading {
    font-size: var(--fs-300);
  }
}
.Entertainment__details-container {
  margin-top: 10rem;
  display: flex;
}
@media (max-width: 600px) {
  .Entertainment__details-container {
    display: block;
    margin-top: 3rem;
  }
  .Entertainment__details-container p {
    font-size: var(--fs-300);
    line-height: 1.5rem;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .Entertainment__details-container {
    margin-top: 5rem;
  }
  .Entertainment__details-container p {
    font-size: var(--fs-400);
    line-height: 1.5rem;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .Entertainment__details-container {
    margin-top: 5rem;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .Entertainment__details-container p {
    font-size: var(--fs-200);
    line-height: 1.5rem;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .Entertainment__details-container {
    margin-top: 0;
  }
}
.Entertainment__details-container img {
  height: 300px;
}
@media (max-width: 600px) {
  .Entertainment__details-container img {
    float: right;
    height: 150px;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .Entertainment__details-container img {
    height: 200px;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .Entertainment__details-container img {
    height: 200px;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .Entertainment__details-container img {
    height: 200px;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .Entertainment__details-container img {
    height: 200px;
  }
}

html {
  background: #ffffff;
  font-family: "DM Sans", sans-serif;
  overflow: hidden;
  user-select: none;
}

body {
  overflow-x: hidden;
}

.header {
  display: flex;
  justify-content: center;
  z-index: 10;
}

p {
  font-size: var(--fs-300);
  line-height: 4rem;
}

.nav-button {
  margin: 1rem 0;
  border-style: none;
  background-color: transparent;
  padding: 2rem;
  margin-top: 3rem;
  margin-right: 5rem;
  margin-bottom: 1rem;
}
@media (max-width: 600px) {
  .nav-button {
    margin-right: 4rem;
    margin-top: 0.5rem;
    padding-bottom: 0;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .nav-button {
    margin-top: 1rem;
    padding-bottom: 0;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .nav-button {
    margin-top: 1rem;
  }
}
.nav-button .line {
  position: relative;
  left: 50%;
}
.nav-button .closed {
  display: block;
  margin-bottom: 1.2rem;
}
@media (max-width: 600px) {
  .nav-button .closed {
    margin-bottom: 0.8rem;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .nav-button .closed {
    margin-bottom: 0.5rem;
  }
}
.nav-button .closed .line {
  height: 2px;
  width: 6rem;
  background: #000;
  display: block;
  transition: all 0.3s;
}
@media (max-width: 600px) {
  .nav-button .closed .line {
    width: 4rem;
    height: 1px;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .nav-button .closed .line {
    width: 4rem;
    height: 1px;
  }
}
.nav-button .open {
  display: block;
  margin-bottom: 1.2rem;
}
@media (max-width: 600px) {
  .nav-button .open {
    margin-bottom: 0.8rem;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .nav-button .open {
    margin-bottom: 0.5rem;
  }
}
.nav-button .open .line {
  height: 2px;
  width: 6rem;
  background: #fff;
  display: block;
  transition: all 0.3s;
}
@media (max-width: 600px) {
  .nav-button .open .line {
    width: 4rem;
    height: 1px;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .nav-button .open .line {
    width: 4rem;
    height: 1px;
  }
}
.nav-button .closed:nth-of-type(2) .line {
  height: 3px;
}
@media (max-width: 600px) {
  .nav-button .closed:nth-of-type(2) .line {
    height: 2px;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .nav-button .closed:nth-of-type(2) .line {
    height: 2px;
  }
}
.nav-button .open:nth-of-type(2) .line {
  height: 3px;
}
@media (max-width: 600px) {
  .nav-button .open:nth-of-type(2) .line {
    height: 2px;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .nav-button .open:nth-of-type(2) .line {
    height: 2px;
  }
}
.nav-button:hover {
  cursor: pointer;
}
.nav-button:hover .open:nth-of-type(1) .line {
  transform: rotate(45deg) translateY(0.9rem);
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .nav-button:hover .open:nth-of-type(1) .line {
    transform: rotate(45deg) translateY(0.4rem);
  }
}
.nav-button:hover .closed:nth-of-type(1) .line {
  transform: rotate(45deg) translateY(0.9rem);
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .nav-button:hover .closed:nth-of-type(1) .line {
    transform: rotate(45deg) translateY(0.4rem);
  }
}
.nav-button:hover .closed:nth-of-type(2) .line {
  transform: rotate(-45deg) translateY(-0.9rem);
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .nav-button:hover .closed:nth-of-type(2) .line {
    transform: rotate(-45deg) translateY(-0.4rem);
  }
}
.nav-button:hover .open:nth-of-type(2) .line {
  transform: rotate(-45deg) translateY(-0.9rem);
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .nav-button:hover .open:nth-of-type(2) .line {
    transform: rotate(-45deg) translateY(-0.4rem);
  }
}

.logo-container {
  height: 4rem;
  padding: 1em;
  flex-basis: 50%;
}
.logo-container img {
  height: 100%;
}

.nav {
  display: flex;
  justify-content: space-between;
  flex-basis: 50%;
  padding: 1em;
}
.nav a {
  color: #6dc0d5;
  text-decoration: none;
}

.page-wrapper {
  width: 100%;
  margin-top: 10rem;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
@media (max-width: 600px) {
  .page-wrapper {
    flex-direction: column;
    margin-top: 3rem;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .page-wrapper {
    margin-top: 0;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .page-wrapper {
    margin-top: 4rem;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .page-wrapper {
    margin-top: 4rem;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .page-wrapper {
    margin-top: 6rem;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .page-wrapper {
    flex-direction: column;
    margin-top: 3rem;
  }
}

.load-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 1000000;
  pointer-events: none;
}

.load-screen {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #000;
  width: 100%;
  height: 100vw;
}

.load-screen1 {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #e4cb58;
  width: 100%;
  height: 0%;
}

.artists-link,
.home-link,
.about-link,
.contact-link {
  position: absolute;
  top: 0;
}

.artists-link {
  left: 3rem;
}

.about-link {
  left: 8rem;
}

.contact-link {
  left: 13rem;
}

.mobile-menu {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 2rem;
  background: white;
  z-index: 100;
  padding: 0.3rem;
  display: none;
  justify-content: space-around;
}
@media (max-width: 600px) {
  .mobile-menu {
    display: flex;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .mobile-menu {
    display: flex;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .mobile-menu {
    display: flex;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .mobile-menu {
    display: flex;
  }
}

.loc-m-button {
  background: none;
  border: none;
  font-size: var(--fs-200);
  color: black;
}

.main-menu {
  position: absolute;
  width: 20%;
  left: 40%;
  background: rgba(0, 0, 0, 0.9);
  transition: height 0.3s ease;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.main-menu a {
  font-size: var(--fs-600);
  position: absolute;
  transition: all 0.3s ease;
  text-decoration: none;
}
@media (max-width: 600px) {
  .main-menu {
    display: none;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 1023px) {
  .main-menu {
    display: none;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .main-menu {
    display: none;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .main-menu {
    display: none;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .main-menu {
    width: 30%;
    left: 35%;
  }
}
@media (min-width: 2560px) and (max-width: 3839px) {
  .main-menu {
    width: 30%;
    left: 35%;
  }
}

.loc-button {
  border: none;
  background-color: transparent;
  color: #00d130;
  font-size: var(--fs-600);
  cursor: pointer;
}
@media (max-width: 600px) {
  .loc-button {
    font-size: var(--fs-400);
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .loc-button {
    font-size: var(--fs-300);
  }
}

.menu-open {
  height: 35vh;
}
@media (max-width: 600px) {
  .menu-open {
    height: 30vh;
  }
}
@media (min-width: 601px) and (max-width: 1180px) {
  .menu-open {
    height: 30vh;
  }
}
@media (min-width: 1181px) and (max-width: 1919px) {
  .menu-open {
    height: 50vh;
  }
}
@media (min-width: 1680px) and (max-width: 2559px) {
  .menu-open {
    height: 45vh;
  }
}
@media (min-width: 2560px) and (max-width: 3839px) {
  .menu-open {
    height: 45vh;
  }
}
@media (orientation:landscape) and (min-width: 601px) and (max-width: 1180px) {
  .menu-open {
    height: 40vh;
  }
}
.menu-open .loc-button {
  color: #fff;
  opacity: 1;
}
.menu-open .loc-button:hover {
  color: #00d130;
}
.menu-open .loc-button:nth-of-type(1) {
  top: 10rem;
}
.menu-open .loc-button:nth-of-type(2) {
  top: 16rem;
}
.menu-open .loc-button:nth-of-type(3) {
  top: 22rem;
}
.menu-open .loc-button:nth-of-type(4) {
  top: 28rem;
}

.menu-closed {
  height: 0;
}
.menu-closed .loc-button {
  top: -10rem;
  color: black;
  opacity: 0;
  display: none;
}

.page-right {
  left: 140%;
}

.page-main {
  left: 0;
  top: 0;
  transition: left 1s ease;
  position: absolute;
  height: 100%;
  overflow-x: hidden;
}

.page-left {
  left: -100%;
}